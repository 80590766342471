import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  QueryList,
  signal,
  ViewChildren,
  WritableSignal
} from '@angular/core'
import { BasisUnterformular, OCRType } from '../basis-unterformular'
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms'
import {
  AktivesEingabefeldHandlerService
} from '../../basis-input-formular-feld/services/aktives-eingabefeld-handler.service'
import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common'
import { DesignSystemModule } from '@dvag/design-system-angular'
import { FormularFeldConfig, UnterformularKonfiguration } from '../../../../store/models/formular-feld-config.model'
import { HaushaltService } from '../../../auftrag-bearbeitung/services/haushalt.service'
import { combineLatest, ReplaySubject, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { Haushaltsmitglied } from '../../../auftrag-bearbeitung/components/kunde/model/haushaltsmitglied.model'
import isEqual from 'lodash/isEqual'
import {
  VersichertePersonReadonlyComponent
} from '../versicherte-person-readonly/versicherte-person-readonly.component'

@Component({
  selector: 'app-versicherte-person-unfall',
  standalone: true,
  imports: [ReactiveFormsModule, DesignSystemModule, NgClass, DatePipe, NgTemplateOutlet, VersichertePersonReadonlyComponent],
  templateUrl: './versicherte-person-unfall.component.html',
  styleUrl: './versicherte-person-unfall.component.scss',
})
export class VersichertePersonUnfallComponent extends BasisUnterformular implements OnInit, OnDestroy {

  @HostBinding('class.formular-field-fullwidth') isFullWidth: boolean
  @ViewChildren('versichertepersoncard') versichertePersonCards: QueryList<HTMLDxCardCollapsableElement>

  predefinedFormGroupConfig: { [key: string]: any } = null
  unterformularFeldConfigs: FormularFeldConfig[] = []
  haushaltsMitglieder: Haushaltsmitglied[] = []
  haushalteSubscription: Subscription
  haushaltsMitgliederAuswahlOptionen: WritableSignal<Haushaltsmitglied[][]> = signal([])

  haushaltsMitgliederSubject: ReplaySubject<Haushaltsmitglied[]> = new ReplaySubject<Haushaltsmitglied[]>(1)
  initialValueSubject: ReplaySubject<any> = new ReplaySubject<any>(1)
  combineInitValueAndMitgliederSubscription: Subscription
  finishedLoading: boolean = false
  readonlyAngaben: any[] = null

  constructor(public override fb: FormBuilder,
              public override focusedControlHandlerService: AktivesEingabefeldHandlerService,
              private haushaltService: HaushaltService) {
    super(fb, focusedControlHandlerService)
    this.predefinedFormGroupConfig = {}
  }

  ngOnDestroy(): void {
    this.haushalteSubscription?.unsubscribe()
    this.combineInitValueAndMitgliederSubscription?.unsubscribe()
  }

  ngOnInit(): void {
    // Change type to HIDDEN if konfiguration hidden is set to true
    this.unterformularFeldConfigs = this.field.unterformularFelder.map(feld => {
      if (feld.konfiguration) {
        const config = feld.konfiguration as UnterformularKonfiguration ?? null
        if (config && config.hidden) {
          feld.type = 'HIDDEN'
        }
      }
      this.predefinedFormGroupConfig[feld.name] = [null, feld.validators ? this.bindValidations(feld.validators) : []]
      return feld
    })

    console.log('this.unterformularFeldConfigs', this.unterformularFeldConfigs)
    // Build the form only when both initialValue and Haushalt are loaded
    this.combineInitValueAndMitgliederSubscription = combineLatest([this.haushaltsMitgliederSubject, this.initialValueSubject])
      .subscribe(([hhMitglieder, angaben]) => {
        this.nestedFormArray.clear()
        this.haushaltsMitglieder = hhMitglieder

        angaben = this.filterAngabenByHaushaltsmitglieder(angaben, hhMitglieder)

        if (angaben && angaben.length > 0) {
          angaben.forEach(initialValue => {
            const nestedForm = this.fb.group({
              ...this.predefinedFormGroupConfig,
            })
            nestedForm.patchValue(initialValue)
            this.nestedFormArray.push(nestedForm)
          })
          if (this.isReadonly) {
            this.readonlyAngaben = this.mapFormArrayToReadonlyValueObject(this.nestedFormArray)
              .map((value) => {
                value.kundenname = this.haushaltsMitglieder.find((mitglied) => mitglied.kundenNummer === value.kundennummer)?.name ?? `Name nicht ermittelbar, Kd.-Nr.: ${value.kundennummer}`
                return value
              })
            console.log('readonlyAngaben', this.readonlyAngaben)
          }
        } else {
          // if only one Haushaltsmitglied exists and is not already added to the form
          // than add it with the Haushaltsmitglied as default value
          // and disable the selection of Haushaltsmitglieder
          if (hhMitglieder.length === 1) {
            if (!this.hasHaushaltsmitgliedUnterformular(this.haushaltsMitglieder[0].kundenNummer)) {
              this.addNestedForm()
              this.nestedFormArray.at(0).get('kundennummer').setValue(this.haushaltsMitglieder[0].kundenNummer)
            }
          }
        }
        this.finishedLoading = true
        this.evaluateAndUpdateHaushaltsMitgliederAuswahlOptionen()
      })

    this.haushalteSubscription = this.haushaltService.kumulierteHaushaltsMitglieder$.pipe(
      map((mitglieder: Haushaltsmitglied[]) => // Entfernt alle doppelten Haushaltsmitglieder mit mehreren Adressen
        mitglieder.filter((mitglied, index, self) =>
          index === self.findIndex((m) => m.kundenNummer === mitglied.kundenNummer),
        ),
      ),
    ).subscribe({
      next: (haushaltsMitglieder: Haushaltsmitglied[]) => {
        this.haushaltsMitgliederSubject.next(haushaltsMitglieder)
      },
      error: () => {
        this.haushaltsMitgliederSubject.error('Could not load Haushaltsmitglieder.')
      },
    })
  }

  private filterAngabenByHaushaltsmitglieder(angaben: any, haushaltsMitglieder: Haushaltsmitglied[]): any {
    const validKundennummern = new Set(haushaltsMitglieder.map(mitglied => mitglied.kundenNummer));
    const filteredAngaben=  angaben?.filter(angabe => angabe.kundennummer && validKundennummern.has(angabe.kundennummer));
    this.initialValue = filteredAngaben?.length > 0 ? filteredAngaben : null
    return filteredAngaben
  }

  override buildFormControl(): AbstractControl {
    return this.fb.group({
      nestedFormArray: this.fb.array([]),
    })
  }

  onHaushaltsmitgliedSelected(index: number) {
    this.evaluateAndUpdateHaushaltsMitgliederAuswahlOptionen()

    if (this.versichertePersonCards && this.versichertePersonCards.length > index) {
      this.versichertePersonCards.toArray()[index].open = true
    }
  }

  evaluateAndUpdateHaushaltsMitgliederAuswahlOptionen(): void {
    this.haushaltsMitgliederAuswahlOptionen.update((options) => {
      const kundenliste = this.nestedFormArray?.controls?.map((control, index) => {
        return control.get('kundennummer').value
      }) ?? []
      return kundenliste.map((kundennummer) => {
        return this.haushaltsMitglieder
          .filter((mitglied) => kundenliste
            .filter(kdnummer => kundennummer !== kdnummer)
            .every((kdnummer) => kdnummer !== mitglied.kundenNummer))
      })
    })
  }

  get nestedFormArray() {
    return this.formControl.get('nestedFormArray') as FormArray
  }

  addNestedForm() {
    const nestedForm = this.fb.group({
      ...this.predefinedFormGroupConfig,
    })
    this.nestedFormArray.push(nestedForm)
    this.evaluateAndUpdateHaushaltsMitgliederAuswahlOptionen()
  }

  hasHaushaltsmitgliedUnterformular(kundenNummer: string): boolean {
    return this.nestedFormArray.controls.some((control) => {
      return control.get('kundennummer').value === kundenNummer
    })
  }

  removeNestedForm(index: number) {
    this.nestedFormArray.removeAt(index)
    this.evaluateAndUpdateHaushaltsMitgliederAuswahlOptionen()
  }

  override readValue(): any {
    const value = this.nestedFormArray.getRawValue() ?? []
    const newValue = Array.from(value).filter((singleValue: any) => !!singleValue['kundennummer'])
    return newValue ?? []
  }

  override setInitialValue(newValue: any) {
    this.initialValue = newValue
    this.initialValueSubject.next(newValue ?? null)
  }

  override async saveNameForOCR(formControlName: string, designSystemElement: any, ocrType: OCRType, formGroupIndex?: number): Promise<void> {
    const nestedForm = this.nestedFormArray.at(formGroupIndex) as FormGroup
    const control = nestedForm.get(formControlName)
    if (!control) {
      this.focusedElement = null
      return
    }
    this.focusedElement = { control: control, dxElement: designSystemElement, ocrType: ocrType }
    await this.focus()
  }

  override hasValueChanged(): boolean {
    // if haushalte and initialValue are not loaded yet, a spinner is displayed
    // therefore no need to check whether the value has changeed
    if( !this.finishedLoading ) {
      return false;
    }

    const currentValue = this.readValue()
    return this.initialValue?.length !== currentValue?.length || !isEqual(this.initialValue, currentValue);
  }

  /**
   * Maps the formArray to a readonly value object. The formArray contains all values, which is used to display the readonly value of the form.
   * @param formArray
   * @private
   */
  private mapFormArrayToReadonlyValueObject(formArray: FormArray): any[] {
    return formArray.controls.map((group: FormGroup) => {
      const obj: { [key: string]: any } = {};
      Object.keys(group.controls).forEach(key => {
        const ocrType = this.unterformularFeldConfigs.find((config) => config.name === key)?.type ?? OCRType.TEXT;
        obj[key] = this.transformValueByOcrType(group.get(key).value, ocrType);
      });
      return obj;
    });
  }
}
