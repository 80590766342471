import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core'
import { DxAlert } from '@dvag/design-system-angular'
import { EnvironmentInfoService } from '../../../environment-info/src/lib/environment-info.service'
import { CameraError } from './camera/camera.component'
import {
  ApplicationInsightsService,
  CustomAppInsightsError
} from '../../../../src/app/services/application-insights.service'

@Component({
  selector: 'lib-fotokollektor',
  templateUrl: './fotokollektor.component.html',
  styleUrls: ['./fotokollektor.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class FotokollektorComponent implements OnInit {

  showPreview = false
  selectImageInPreview = 0
  images: string[] = []
  originalImages: string[] = []
  showCamera = false
  hideCloseButton = false

  @Input()
  set hideDialogCloseButton(value: boolean) {
    this.hideCloseButton = value
    this.changeDetectorRef.detectChanges()
  }

  @Output()
  imagesCollected: EventEmitter<string[]> = new EventEmitter<string[]>()

  @Output()
  errorOccurred: EventEmitter<any> = new EventEmitter<any>()

  @Output()
  processAborted: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild('cameraerror') cameraErrorDialog: DxAlert
  @ViewChild('surfacefirefoxerror') surfaceFirefoxErrorDialog: DxAlert
  @ViewChild('imagecapturingerror') imageCapturingErrorAlert: DxAlert

  constructor(private changeDetectorRef: ChangeDetectorRef, private environmentInfoService: EnvironmentInfoService, private applicationInsightsService: ApplicationInsightsService) {
    console.log('FotokollektorComponent: Loaded')
  }

  ngOnInit(): void {
    if (this.environmentInfoService.checkDeviceIsWindowsSurface() && this.environmentInfoService.checkBrowserIsFirefox()) {
      this.showCamera = false
      setTimeout(() => {
        this.showErrorSurfaceFirefoxDialog()
        this.applicationInsightsService.logError('FotokollektorComponent', new CustomAppInsightsError('Tried to open camera on a Windows Surface with Mozilla Firefox', { device: 'Windows Surface', localhost: window.location.hostname}))
      }, 0)
    } else {
      this.showCamera = true
      this.changeDetectorRef.detectChanges()

      this.hideUserlaneAvatar(true)
    }
  }

  showErrorSurfaceFirefoxDialog() {
    this.surfaceFirefoxErrorDialog.visible = true
    this.changeDetectorRef.detectChanges()
  }

  showErrorCameraDialog(event: CameraError) {
    this.preparationBeforeErrorAlert()
    switch (event.type) {
      case 'ConstraintsError' :
        this.cameraErrorDialog.titlestring = 'Fotoaufnahme nicht möglich'
        this.cameraErrorDialog.content = 'Die Mindestauflösung der Kamera wurde nicht erreicht. Nutzen Sie alternativ die Funktion „Mit anderem Gerät fotografieren“ und nehmen Sie die Dokumente mit Ihrem Smartphone auf.'
        break
      case 'CameraError' :
        this.cameraErrorDialog.titlestring = 'Fehlende Kameraberechtigung'
        this.cameraErrorDialog.content = 'Bitte erlauben Sie den Zugriff auf die Kamera in den Browsereinstellungen, um die Fotofunktion nutzen zu können.'
        break
    }
    this.cameraErrorDialog.visible = true
    this.changeDetectorRef.detectChanges()
  }

  showImageCapturedErrorDialog() {
    this.preparationBeforeErrorAlert()
    this.imageCapturingErrorAlert.visible = true
    this.changeDetectorRef.detectChanges()
  }

  confirmErrorDialog(dxAlert: DxAlert) {
    this.errorOccurred.emit()
    this.hideUserlaneAvatar(false)
    dxAlert.visible = false
    this.changeDetectorRef.detectChanges()
  }

  extendedImages(images: string[]): void {
    this.changedImages(images)
    this.selectImageInPreview = this.images.length - 1
  }

  extendedOriginalImages(originalImages) {
    this.originalImages = [...originalImages]
  }

  changedImages(images: string[]): void {
    this.images = images
  }

  updateOriginalImages(images: string[]){
    this.originalImages = images
  }

  changeSelectedImage(imageIndex) {
    this.selectImageInPreview = imageIndex
  }

  abortProcess() {
    // set images to empty array to trigger directly a finalized event
    this.images = []
    this.hideUserlaneAvatar(false)
    this.processAborted.emit()
  }

  hideUserlaneAvatar(hide: boolean) {
    // TODO: ViewChild statt document.getElement
    const usln = [document.getElementById('usln-p-assistant-avatar'),
      document.getElementById('userlane-assistant-container'),
      document.getElementById('userlike')]

    usln.forEach( element => {
      if (element) {
        element.style.display = hide ? 'none' : null
      }
    })
    this.changeDetectorRef.detectChanges()
  }

  closeDialog() {
    this.surfaceFirefoxErrorDialog.visible = false
    this.showPreview = false
    this.changeDetectorRef.detectChanges()
    this.showCamera = false
    this.hideUserlaneAvatar(false)
    this.changeDetectorRef.detectChanges()
    this.done()
    this.cleanUpData()
  }

  openPreview(show: boolean) {
    this.showPreview = show
    this.changeDetectorRef.detectChanges()
  }

  done() {
    this.imagesCollected.emit(this.images)
    this.cleanUpData()
  }

  cleanUpData() {
    this.images = []
    this.selectImageInPreview = 0
  }

  private preparationBeforeErrorAlert() {
    this.showPreview = false
    this.showCamera = false
    this.changeDetectorRef.detectChanges()
  }

}
