import { AbstractControl, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms'
import {
  AktivesEingabefeldHandlerService
} from '../basis-input-formular-feld/services/aktives-eingabefeld-handler.service'
import { BasisInputFormularFeld } from '../basis-input-formular-feld/basis-input-formular-feld'
import { FormularFeldConfig } from '../../../store/models/formular-feld-config.model'
import {
  FormularFeldDictionaryService
} from '../../auftrag-bearbeitung/components/vertrags-formular/services/formular-feld-dictionary.service'
import { DecimalPipe } from '@angular/common'

export enum OCRType {
  TEXT = 'TEXT',
  BETRAG = 'BETRAG',
  PROZENT = 'PROZENT',
  DATUM = 'DATUM',
  AUSWAHL = 'AUSWAHL',
}

export interface UnterformularFeldConfig {
  ocrType: OCRType
  name: string,
  label: string,
  validation: {validator: ValidatorFn, text: string},
  auswahlOptionen?: string[];
}

export class BasisUnterformular extends BasisInputFormularFeld {

  protected readonly OCRType = OCRType
  focusedElement: { control: AbstractControl, dxElement: any, ocrType: OCRType } = null

  constructor(public override fb: FormBuilder, public override focusedControlHandlerService: AktivesEingabefeldHandlerService) {
    super(fb, focusedControlHandlerService)
  }

  override buildFormControl(): AbstractControl {
    return this.fb.control(
      null,
      //this.bindValidations(this.field.validators)
    )
  }

  override setInitialValue(value: any): void {
    this.initialValue = value
    this.patchValue(value)
  }

  override patchValue(newValue): void {
    this.formControl?.patchValue(newValue)
    this.validate(newValue)
  }

  override validate(value: any): void {
    this.errormessage = ''
    this.formControl.updateValueAndValidity()
  }

  override readValue(): any {
    if (this.formControl?.value === '') {
      return null
    }
    return this.formControl?.value ?? null
  }

  override async setOcrResultToFormControl(ocrText: string): Promise<void> {
    if (!this.focusedElement) {
      return
    }

    if (!ocrText) {
      await this.focus()
      return
    }

    let valueOfFormControl = this.focusedElement.control.value
    let newValue
    switch (this.focusedElement.ocrType) {
      case OCRType.TEXT:
        newValue = (valueOfFormControl ?? '') + ocrText
        break
      case OCRType.BETRAG:
      case OCRType.PROZENT:
        newValue = this.getBetragValue(ocrText, valueOfFormControl)
        break
      default:
        newValue = ocrText
        break
    }
    this.focusedElement.control.patchValue(newValue)
    await this.focus()
  }

  override async focus(): Promise<void> {
    this.focusedElement?.dxElement?.focusControl()
    // Das Unterformular muss als fokussiertes Element gespeichert werden, damit das OCR-Ergebnis hier ankommt
    this.storeNameOfFocusedElement(this.field.name)
  }

  override storeNameOfFocusedElement(fieldName: string): void {
    this.focusedControlHandlerService.aktivesEingabefeld = {
      setText: async (text: string) => {
        await this.setOcrResultToFormControl(text)
      },
      focus: async () => {
        await this.focus()
      }
    }
  }

  async saveNameForOCR(formControlName: string, designSystemElement: any, ocrType: OCRType): Promise<void> {
    const control = this.formControl.get(formControlName)
    if (!control) {
      this.focusedElement = null
      return
    }
    this.focusedElement = { control: control, dxElement: designSystemElement, ocrType: ocrType }
    await this.focus()
  }

  protected decimalPipe: DecimalPipe = new DecimalPipe('de-DE')
  protected transformValueByOcrType(value: any, type: string): string {
    if (value === null || value === undefined || value === '') {
      return '--';
    }

    switch (type.toUpperCase()) {
      case OCRType.BETRAG:
        return `${this.decimalPipe.transform(value, '1.2-2')} €`
      case OCRType.PROZENT:
        return `${this.decimalPipe.transform(value, '1.2-2')} %`
      default:
        return value.toString();
    }
  }
}
