import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core'
import { DesignSystemModule } from '@dvag/design-system-angular'
import { FormularFeldConfig } from '../../../../store/models/formular-feld-config.model'

@Component({
  selector: 'app-versicherte-person-readonly',
  standalone: true,
  imports: [
    DesignSystemModule
  ],
  templateUrl: './versicherte-person-readonly.component.html',
  styleUrl: './versicherte-person-readonly.component.scss'
})
export class VersichertePersonReadonlyComponent {

  @Input() finishedLoading: boolean = false
  @Input() readonlyAngaben: any[] = null
  @Input() unterformularFeldConfigs: FormularFeldConfig[] = []
  @Input() unterformularName: string = ''

  @ViewChildren('readonlyAngaben') readonlyAngabenElements: QueryList<ElementRef>
  @ViewChildren('readonlychevrons') readonlyChevronElements: QueryList<HTMLDxIconElement>

  toogleReadonly(index: number) {
    const displayStyle = this.readonlyAngabenElements.toArray()[index].nativeElement.style.display;
    if(displayStyle === 'none') {
      this.readonlyAngabenElements.toArray()[index].nativeElement.style.display = 'flex';
      this.readonlyChevronElements.toArray()[index].icon = 'Chevron-hoch';
    } else {
      this.readonlyAngabenElements.toArray()[index].nativeElement.style.display = 'none';
      this.readonlyChevronElements.toArray()[index].icon = 'Chevron-unten';
    }
    this.readonlyAngabenElements.toArray()[index].nativeElement.style.display = displayStyle === 'none' ? 'flex' : 'none';
  }
}
