import { Injectable } from '@angular/core'
import { BucketAccessInfo, BucketInfo } from '../model/buckets.model'
import { map, mergeMap, tap } from 'rxjs/operators'
import { from, Observable } from 'rxjs'
import { Payload } from '../model/payload'
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http'
import { VerarbeitungsErgebnis } from '../model/verarbeitungs-ergebnis.model'
import { Stage } from '../stage/stage'
import { BackendConfigObject } from '../model/config-options.model'
import { LicenseService } from './license.service'



@Injectable({
  providedIn: 'root'
})
export class BucketsHttpService {

  constructor(private httpClient: HttpClient,
              private stage: Stage,
              private licenseService: LicenseService) { }

  erstelleBucket(backendConfigObject: BackendConfigObject): Observable<BucketAccessInfo> {
    // MOCK: Bucket Erstellung
    // return of(testObject.bucketAccessInfoMockBuilder.build())
    return this.httpClient.post<BucketAccessInfo>(`${this.stage.getBackendUrl()}/buckets`, {bucketConfig: backendConfigObject})
      .pipe(
        tap((bucketAccessInfo) => {
          this.licenseService.setLicense(bucketAccessInfo?.dl)
        })
      )
  }

  ladeBucketInfos(accessKey: string): Observable<BucketInfo> {
    return this.httpClient.get<BucketInfo>(`${this.stage.getBackendUrl()}/buckets`,
      { params: { accessKey } }
    ).pipe(
      tap((bucketAccessInfo) => {
        this.licenseService.setLicense(bucketAccessInfo?.dl)
      })
    )
  }

  erstelleDokumentImBucket(bucketId: string): Observable<string> {
    return this.httpClient.post<{ dokumentId: string }>(`${this.stage.getBackendUrl()}/buckets/${bucketId}/dokumente`, '')
      .pipe(
        map(res => res.dokumentId)
      )
  }

  addDateiOderBildZumDokument(bucketId: string, dokumentId: string, position: number, payload: Payload): Observable<HttpEvent<{}>> {
    return from(payload.getFormData('file'))
      .pipe(
        mergeMap(formData =>
          this.httpClient.post<{ ergebnis: any }>(`${this.stage.getBackendUrl()}/buckets/${bucketId}/dokumente/${dokumentId}/dateien/${position}`, formData,
            {
              reportProgress: true,
              observe: 'events',
              headers: new HttpHeaders({ 'ngsw-bypass': 'true' })
            }))
      )
  }

  loescheDokumenteImBucket(bucketId: string): Observable<any> {
    return this.httpClient.delete<any>(`${this.stage.getBackendUrl()}/buckets/${bucketId}/dokumente`)
  }

  verarbeiteDokument(bucketId: string, dokumentId: string): Observable<VerarbeitungsErgebnis> {
    return this.httpClient.post<VerarbeitungsErgebnis>(`${this.stage.getBackendUrl()}/buckets/${bucketId}/dokumente/${dokumentId}/verarbeiten`, '')
  }

  dokumentVorverarbeiten(bucketId: string, dokumentId: string){
    return this.httpClient.post<any>(`${this.stage.getBackendUrl()}/buckets/${bucketId}/dokumente/${dokumentId}/vorverarbeiten`, '')
  }

  verarbeiteDokumente(bucketId: string, dokumentIds: string[]){
    return this.httpClient.post<any>(`${this.stage.getBackendUrl()}/buckets/${bucketId}/dokumente/bulk-verarbeiten`, dokumentIds)
  }

}
