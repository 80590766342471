import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { EnvironmentInfoService } from '../../../../environment-info/src/lib/environment-info.service'
import { ImageCropperComponent } from '../../../../image-cropper/src/lib/image-cropper.component'
import { LicenseService } from '../../../../upload-components/src/lib/services/license.service'

@Component({
  selector: 'lib-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent {

  zoomImage = false
  isLoading = false
  public license$ = this.licenseService.license$

  @Input()
  images: string[]

  @Input()
  originalImages: string[]

  @Input()
  selectedImage: number

  @Output()
  capturedImagesChanged: EventEmitter<string[]> = new EventEmitter<string[]>()

  @Output()
  updateOriginalImages: EventEmitter<string[]> = new EventEmitter<string[]>()

  @Output()
  selectedImageChanged: EventEmitter<number> = new EventEmitter<number>()

  @Output()
  captureNewImage: EventEmitter<any> = new EventEmitter<any>()

  @Output()
  triggerUpload: EventEmitter<any> = new EventEmitter<any>()

  @Output()
  cropperIsOpen: EventEmitter<boolean> = new EventEmitter<boolean>()

  public cropperComponent: ImageCropperComponent

  constructor(public environmentInfoService: EnvironmentInfoService,
              private changeDetectionRef: ChangeDetectorRef,
              private licenseService: LicenseService) {
  }

  cropperReady(component: ImageCropperComponent) {
    this.cropperComponent = component
  }

  handleCroppedImage(image: string) {

    // Überprüfe, ob das Bild tatsächlich geändert wurde
    if (this.images[this.selectedImage] === image) {
      this.changeDetectionRef.detectChanges()
      return
    }

    try {
      this.images[this.selectedImage] = image
      this.changeDetectionRef.detectChanges()

    } catch (error) {
      console.error('Error processing cropped image:', error)
      this.changeDetectionRef.detectChanges()
    }
  }

  onDbClick() {
    if (this.environmentInfoService.checkDeviceIsSmartphone()) {
      this.zoomImage = true
      this.changeDetectionRef.detectChanges()
    }
  }

  dropImage(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.images, event.previousIndex, event.currentIndex)
    this.selectedImage = event.currentIndex
    this.capturedImagesChanged.emit(this.images)
    this.selectedImageChanged.emit(this.selectedImage)
    this.changeDetectionRef.detectChanges()
  }

  addImage() {
    this.captureNewImage.emit(null)
  }

  done() {
    this.triggerUpload.emit(null)
  }

  deleteImage(idx = -1) {
    let imageToDelete: number
    if (idx < 0) { // loeschen ueber das trash Icon (mobile Devices)
      imageToDelete = this.selectedImage
    } else { // loeschen ueber das X am Thumbnail
      imageToDelete = idx
    }

    // selectedImage < imageToDelete: selectedImage bleibt, Element loeschen, fire Event capturedImagesChanged
    // selectedImage === imageToDelete: selectedImage bleibt, fire Event capturedImagesChanged
    // selectedImage === imageToDelete + ausser es ist das letzte Element: selectedImage-1, fire Event selectedImageChangedselectedImage bleibt, fire Event capturedImagesChanged
    console.log('selectedImage: %s --- imageToDelete: %s', this.selectedImage, imageToDelete)
    // Setze selected image eins herab, da das nachfolgende Foto gelöscht wird
    if (this.selectedImage > imageToDelete || (this.selectedImage === imageToDelete && this.selectedImage === this.images.length - 1)) {
      this.selectedImage -= 1
      this.selectedImageChanged.emit(this.selectedImage)
    }

    this.images.splice(imageToDelete, 1)
    this.originalImages = this.originalImages?.filter((element, index) => index !== imageToDelete)

    this.changeDetectionRef.detectChanges()
    this.capturedImagesChanged.emit(this.images)
    this.updateOriginalImages.emit(this.originalImages)

    if (this.images.length === 0) {
      this.captureNewImage.emit(null)
    }
  }

  preventContextMenu(event: Event) {
    event.preventDefault()
  }

  showPreviousImg(): void {
    if (this.selectedImage > 0) {
      this.selectedImage--
      this.selectedImageChanged.emit(this.selectedImage)
      this.changeDetectionRef.detectChanges()
    }
  }

  showNextImg(): void {
    if (this.selectedImage < this.images.length - 1) {
      this.selectedImage++
      this.selectedImageChanged.emit(this.selectedImage)
      this.changeDetectionRef.detectChanges()
    }
  }

  setSelectedImage(idx: number) {
    this.selectedImage = idx
    this.selectedImageChanged.emit(this.selectedImage)
    this.changeDetectionRef.detectChanges()
  }

  zoom(zoom: boolean) {
    this.zoomImage = zoom
    this.changeDetectionRef.detectChanges()
  }

  openCropper(image: string, idx: number) {
    this.isLoading = true
    this.cropperIsOpen.emit(true)
    this.selectedImage = idx

    this.cropperComponent.showCropper(image)
      .then(() => {
      })
      .catch((error) => {
        console.error('Error during showCropper:', error)
      })
      .finally(() => {
        this.isLoading = false
        this.changeDetectionRef.detectChanges()
      });
  }

  onCropperOpen(event) {
    this.cropperIsOpen.emit(event)
  }
}
