import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core'
import { DxAlert, DxModal } from '@dvag/design-system-angular'
import { UploadDialogService } from '../services/upload-dialog.service'
import { ConfigOptions } from '../model/config-options.model'
import { ModalButtonConfig } from '../model/modal-button-config.model'
import { Subscription } from 'rxjs'
import { DateiauswahlComponent } from '../../../../dateiauswahl/src/lib/dateiauswahl.component'
import { BucketAccessInfo } from '../model/buckets.model'
import { FilePayload } from '../model/payload'
import {
  ApplicationInsightsService,
  UploadDialogType
} from '../../../../../src/app/services/application-insights.service'

export enum FileUploadErrorType {
  CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
  CUSTOMER_NO_EWE = 'CUSTOMER_NO_EWE',
}

@Component({
  selector: 'lib-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent implements OnDestroy {

  @Input()
  kundennr: string

  @Input()
  configoptions: ConfigOptions = {}

  @Input()
  get openModal(): (() => Promise<void>) {
    return async (): Promise<void> => {
      this.modalButtonsSubscription = this.uploadDialogService.modalButtons$.subscribe({
        next: (buttons) => {
          this.modalButtons = buttons
          // *********************
          // TODO: Workaround für Ticket DSYS-1302 entfernen sobald umgesetzt
          // Mit dem Setzen des Modal-Titels wird das Modal neu gerendert, wodurch auch die Buttons neu geslottet werden.
          this.modalTitle += ' '
          // *********************
          this.changeDetectorRef.detectChanges()
        },
      })
      this.modalTitleSubscription = this.uploadDialogService.modalTitle$.subscribe({
        next: (title) => {
          this.modalTitle = title
          this.changeDetectorRef.detectChanges()
        },
      })
      const validationResult = this.uploadDialogService.validateInputParameters('FILE_FOTO_DIALOG', this.configoptions, this.kundennr, null)
      if (validationResult.status === 'ERROR') {
        this.uploadDialogService.closeUploadProcess('ERROR', validationResult.message)
        this.errorAlert.visible = true
        return
      }
      this.mergedConfigOptions = this.uploadDialogService.initConfigOptions(this.configoptions)
      this.dxmodal.visible = true
      await this.initView()
      this.applicationInsightsService.logOpenedUploadDialog(UploadDialogType.File)
      this.changeDetectorRef.detectChanges()
    }
  }

  @Output()
  bucketCreatedEvent: EventEmitter<string> = new EventEmitter<string>()

  @ViewChild('dxmodal') dxmodal: DxModal
  @ViewChild('dateiauswahl') dateiauswahl: DateiauswahlComponent
  @ViewChild('fileuploaderroralert') errorAlert: DxAlert

  FileUploadErrorType = FileUploadErrorType

  // TODO: wird der wirklich gebraucht oder in der Dateiauswahl?
  cropperIsOpen = false
  mergeToPdfByDefault = false
  modalClosedEvent: CustomEvent
  showDateiauswahl = false
  verfiedKundennummer: string = undefined
  showSpinner: boolean = false
  showError: boolean = false
  errorType: FileUploadErrorType
  userContext: 'VB' | 'ENDKUNDE' = 'VB'
  bucketAccessInfo: BucketAccessInfo
  mergedConfigOptions: ConfigOptions = undefined

  modalTitle = 'Dokumente hochladen'
  private modalTitleSubscription: Subscription

  modalButtons: ModalButtonConfig[]
  private modalButtonsSubscription: Subscription

  constructor(private uploadDialogService: UploadDialogService, private changeDetectorRef: ChangeDetectorRef, private applicationInsightsService: ApplicationInsightsService) {
  }

  ngOnDestroy() {
    this.modalButtonsSubscription?.unsubscribe()
    this.modalTitleSubscription?.unsubscribe()
  }

  onModalClosed(e: CustomEvent) {
    e.detail.preventCloseModal()
    if (e.detail.reason === 'background') {
      return
    }
    // Kind-Element macht nun die Prüfung ob geschlossen werden kann oder nicht und triggert dann den CloseModal-OutputEvent
    if (!this.showDateiauswahl) {
      this.closeAndResetModal()
      return
    }
    this.modalClosedEvent = e
    this.changeDetectorRef.detectChanges()
  }

  private resetDialog() {
    this.modalClosedEvent = undefined
    this.showDateiauswahl = false
    this.cropperIsOpen = false
    this.showSpinner = false
    this.showError = false
    this.errorAlert.visible = false
    this.bucketAccessInfo = undefined
    this.verfiedKundennummer = undefined
    this.mergedConfigOptions = undefined
    this.modalButtonsSubscription?.unsubscribe()
    this.modalTitleSubscription?.unsubscribe()
    this.changeDetectorRef.detectChanges()
  }

  handleUseButtons(buttons: ModalButtonConfig[]) {
    this.uploadDialogService.showModalButtons(buttons)
  }

  changeModalTitle(title: string) {
    if(title) {
      this.uploadDialogService.setModalTitle(title)
    } else {
      this.uploadDialogService.setModalTitle(this.mergedConfigOptions.maxCountOfDocuments === 1 ? 'Dokument hochladen' : 'Dokumente hochladen')
    }
  }

  cancelUpload() {
    this.uploadDialogService.closeUploadProcess('CANCELED')
    this.closeAndResetModal()
  }

  closeAndResetModal() {
    this.dxmodal.visible = false
    this.resetDialog()
  }

  private async initView() {
    this.uploadDialogService.setModalTitle(this.mergedConfigOptions.maxCountOfDocuments === 1 ? 'Dokument hochladen' : 'Dokumente hochladen')
    if (this.mergedConfigOptions.useGenericCustomer) {
      this.showDateiauswahl = true
      await this.createBucket()
      this.changeDetectorRef.detectChanges()
      return
    }

    this.showSpinner = true
    this.changeDetectorRef.detectChanges()
    try {
      const kunde = await this.uploadDialogService.ladeKunde(this.kundennr)
      console.log('initView: ', kunde)
      if (kunde.eweZugestimmt === false && this.mergedConfigOptions.checkEWE) {
        this.showErrorContent(FileUploadErrorType.CUSTOMER_NO_EWE)
        return
      }
      this.verfiedKundennummer = kunde.nummer
      this.showDateiauswahl = true
      await this.createBucket(this.verfiedKundennummer)
    } catch (error) {
      this.showErrorContent(FileUploadErrorType.CUSTOMER_NOT_FOUND)
    } finally {
      this.showSpinner = false
      this.changeDetectorRef.detectChanges()
    }
  }

  private showErrorContent(errorType: FileUploadErrorType) {
    this.errorType = errorType
    this.showSpinner = false
    this.userContext = this.mergedConfigOptions.userContext
    this.showError = true
    this.changeDetectorRef.detectChanges()
  }

  async createBucket(kundennummer?: string) {
    console.log('createBucket')
    try {
      this.bucketAccessInfo = await this.uploadDialogService.createBucket(kundennummer ?? undefined)
      this.bucketCreatedEvent.emit(this.bucketAccessInfo.bucketId ?? '')
    } catch (error) {
      this.errorAlert.visible = true
      this.uploadDialogService.closeUploadProcess('ERROR', 'Bucket creation failed.')
    }
  }

  uploadDokument(uploadData: { dateien: File[]; mergeToPdf: boolean }) {
    const payloads = uploadData.dateien.map(file => new FilePayload(file))
    this.uploadDialogService.uploadDokument(payloads, this.bucketAccessInfo.bucketId, uploadData.mergeToPdf)
    this.dxmodal.visible = false
    this.resetDialog()
  }
}
